import { cloneDeep } from "lodash";
import objectAssign from "object-assign";

import * as actions from "./../../types/actionTypes";
import {
  addToCart,
  removeItemFromCart,
  updateProductQuantity,
  resetCart,
} from "./../utils/cart";
import initialState from "./../initialState";

import * as cookieStorage from "./../../actions/common/cookieStorage";

const cStorage = cookieStorage.getCookieStorage();

const handlers = new Map();

handlers.set(actions.LOAD_TIRE_DIMS_WIDTHS, (state, action) => {
  return objectAssign({}, state, {
    widths: action.widths,
  });
});

handlers.set(actions.MOTO_SELECT_WIDTH_DIM, (state, action) => {
  return objectAssign({}, state, {
    selectedWidth: action.selectedWidth,
  });
});

handlers.set(actions.MOTO_TIRE_DIMS_PROFILES, (state, action) => {
  return objectAssign({}, state, {
    profiles: action.profiles,
  });
});

handlers.set(actions.LOAD_TOP_SELLERS, (state, action) => {
  return objectAssign({}, state, {
    topSellers: action.products,
  });
});

handlers.set(actions.LOAD_POPULAR_SIZES, (state, action) => {
  return objectAssign({}, state, {
    popularSizes: action.metrics,
  });
});

handlers.set(actions.ADMIN_STATUS_INFLIGHT, (state) => {
  const admin = objectAssign({}, state.admin, {
    during: true,
  });

  return objectAssign({}, state, {
    admin,
  });
});

handlers.set(actions.ADMIN_LOAD_ORDER_PAYMENT, (state, action) => {
  const orderIndex = state.admin.orders.findIndex(
    (o) => o.orderId === action.orderId
  );
  state.admin.orders[orderIndex].cData = action.cData;

  const admin = objectAssign({}, state.admin, {
    orders: state.admin.orders,
  });

  return objectAssign({}, state, {
    admin,
  });
});

handlers.set(actions.ADMIN_ORDER_UPDATED, (state, action) => {
  const admin = objectAssign({}, state.admin, {
    during: false,
    orders: action.result.orders,
    products: action.result.products,
  });

  return objectAssign({}, state, {
    admin,
  });
});

handlers.set(actions.ADMIN_LOAD_ORDERS, (state, action) => {
  const admin = objectAssign({}, state.admin, {
    orders: action.orders,
    products: action.products,
  });

  return objectAssign({}, state, {
    admin,
  });
});

handlers.set(actions.MOTO_SELECT_PROFILE_DIM, (state, action) => {
  return objectAssign({}, state, {
    selectedProfile: action.selectedProfile,
  });
});

handlers.set(actions.MOTO_TIRE_DIMS_RIMS, (state, action) => {
  return objectAssign({}, state, {
    rims: action.rims,
  });
});

handlers.set(actions.MOTO_SELECT_RIM_DIM, (state, action) => {
  return objectAssign({}, state, {
    selectedRim: action.selectedRim,
  });
});

handlers.set(actions.MOTO_RESET_DIMS, (state) => {
  return objectAssign({}, state, {
    profiles: [],
    rims: [],
    selectedProfile: "",
    selectedRim: "",
  });
});

handlers.set(actions.LOAD_MOTO_TIRES, (state, action) => {
  return objectAssign({}, state, {
    motoTires: action.motoTires.result,
    nextShipment: action.motoTires.settings.nextShipmentDisplayTime,
    currentShipment: action.motoTires.settings.currentShipmentDisplayTime,
    nextShipmentDeadline: action.motoTires.settings.nextShipmentOrderDeadline,
    getCreationTiresPredictedYearsForPreorder: action.motoTires.years.tireYears,
    listPending: false,
  });
});

handlers.set(actions.BEST_OFFER_SENT, (state, action) => {
  let bestOfferEANs = [].concat(state.bestOfferEANs);
  bestOfferEANs.push(action.data.ean);

  return objectAssign({}, state, {
    bestOfferEANs,
  });
});

handlers.set(actions.LOADING, (state, action) => {
  return objectAssign({}, state, {
    listPending: action.loading,
  });
});

handlers.set(actions.LOAD_MOTO_TIRES_BY_CAT, (state, action) => {
  return objectAssign({}, state, {
    motoTires: action.motoTires,
    nextShipment: action.nextShipment,
    category: action.category,
    listPending: false,
  });
});

handlers.set(actions.LOAD_PRODUCT_INFLIGHT, (state) => {
  return objectAssign({}, state, {
    product: null,
    productPending: true,
  });
});

handlers.set(actions.LOAD_PRODUCT, (state, action) => {
  return objectAssign({}, state, {
    product: action.product,
    nextShipment: action.nextShipment,
    currentShipment: action.currentShipment,
    nextShipmentDeadline: action.nextShipmentDeadline,
    productNotFound: action.productNotFound,
    getCreationTiresPredictedYearsForPreorder: action.tireYears,
    productPending: false,
    listPending: false,
  });
});

handlers.set(actions.UPDATE_FILTERS, (state, { filters }) => {
  return objectAssign({}, state, { filters });
});

handlers.set(actions.ADD_PRODUCT_TO_CART, (state, action) => {
  const cart = addToCart(action.ean, action.isSet);

  return objectAssign({}, state, {
    cart,
  });
});

handlers.set(actions.TOGGLE_SNACKBAR, (state, action) => {
  return objectAssign({}, state, {
    toggleSnackbar: action.toggle,
    snackbar: action.snack,
  });
});

handlers.set(actions.CART_PRODUCTS_DATA, (state, action) => {
  // Inspect state to find out if we have a dirty state from the last thank you page
  let resetThankyou = {};

  if (state.thankyou === true) {
    resetThankyou = {
      thankyou: false,
      orderId: undefined,
    };
  }

  return objectAssign(
    {},
    state,
    {
      cartProducts: action.results,
      nextShipment: action.nextShipment,
      currentShipment: action.currentShipmentDisplayTime,
      nextShipmentDeadline: action.nextShipmentOrderDeadline,
    },
    resetThankyou
  );
});

handlers.set(actions.REMOVE_PRODUCT_FROM_CART, (state, action) => {
  const cart = removeItemFromCart(action.ean);

  return objectAssign({}, state, {
    cart,
  });
});

handlers.set(actions.CHANGE_PRODUCT_QUANTITY_IN_CART, (state, action) => {
  const cart = updateProductQuantity(action.ean, action.quantity);

  return objectAssign({}, state, {
    cart,
  });
});

handlers.set(actions.CHANGE_SIP, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    sip: action.sip,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.LOAD_INSTALL_CITIES, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    cities: action.cities,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.LOAD_CITIES_FOR_SHOPS_PAGE, (state, action) => {
  const shopIndex = objectAssign({}, state.shopIndex, {
    cities: action.cities,
  });

  return objectAssign({}, state, {
    shopIndex,
  });
});

handlers.set(actions.LOAD_SHOPS_INDEX_PAGE, (state, action) => {
  const shopIndex = objectAssign({}, state.shopIndex, {
    shops: action.shops,
  });

  return objectAssign({}, state, {
    shopIndex,
  });
});

handlers.set(actions.LOAD_SHOP_BY_NAME, (state, action) => {
  const shopIndex = objectAssign({}, state.shopIndex, {
    shop: action.shop,
  });

  return objectAssign({}, state, {
    shopIndex,
  });
});

handlers.set(actions.PICK_CITY_SHOPS_PAGE, (state, action) => {
  const shopIndex = objectAssign({}, state.shopIndex, {
    selectedCity: action.city,
  });

  return objectAssign({}, state, {
    shopIndex,
  });
});

handlers.set(actions.CHANGE_INSTALL_CITY, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    selectedCityId: action.city,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.LOAD_SHOPS, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    shops: action.shops,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.CHANGE_INSTALL_SHOP, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    selectedShop: action.shopId,
    dates: [],
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.CLEAR_PICK_SHOP, (state) => {
  const checkout = objectAssign({}, state.checkout, {
    selectedShop: "",
    dates: [],
    slots: [],
    installDate: "",
    installSlot: "",
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.LOAD_OPENDATES_FOR_INSTALLATION, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    dates: action.dates,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.CHANGE_PAYMENT_METHOD, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    selectedPaymentMethod: action.method,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.LOAD_INSTALL_SLOTS, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    slots: action.slots,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.PICK_INSTALL_DATE, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    installDate: action.date,
    slots: [],
    installSlot: "",
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.COMPLETE_ORDER, (state, action) => {
  // Reset the cart
  resetCart();

  return objectAssign({}, state, {
    cart: {
      items: [],
    },
    checkout: initialState.catalog.checkout,
    thankyou: true,
    orderId: action.result.orderId.toString(),
  });
});

handlers.set(actions.CHECKOUT_RESET_CART_CONDITIONS, (state) => {
  return objectAssign({}, state, {
    cart: {
      items: [],
    },
    checkout: initialState.catalog.checkout,
    thankyou: false,
  });
});

handlers.set(actions.CHECKOUT_ACTIVE_STEP, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    activeStep: action.stepIndex,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.CHECKOUT_ORDER_ERROR, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    activeStep: 1,
    loading: false,
    checkoutError: true,
    checkoutErrorMessage: action.result.message,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.PICK_INSTALL_SLOT, (state, action) => {
  const checkout = objectAssign({}, state.checkout, {
    installSlot: action.slot,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.LOADER_IS_LOADING, (state, { value }) => {
  const checkout = objectAssign({}, state.checkout, {
    loading: value,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.FORM_FIELD_CHANGE_PAYMENT, (state, { name, value }) => {
  const modifier = {};
  modifier[name] = value;

  const payment = objectAssign({}, state.checkout.payment, modifier);

  const checkout = objectAssign({}, state.checkout, {
    payment,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

//New handler for additional comments
handlers.set(actions.CHANGE_ADDITIONAL_NOTES, (state, { value }) => {
  const checkout = objectAssign({}, state.checkout, {
    additionalNotes: value,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.FORM_FIELD_CHANGE_PERSONAL, (state, { name, value }) => {
  const modifier = {};
  modifier[name] = value;

  const personal = objectAssign({}, state.checkout.personal, modifier);

  const checkout = objectAssign({}, state.checkout, {
    personal,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.FORM_FIELD_CHANGE_SHIPPING, (state, { name, value }) => {
  const modifier = {};
  modifier[name] = value;

  const shipping = objectAssign({}, state.checkout.shipping, modifier);

  const checkout = objectAssign({}, state.checkout, {
    shipping,
  });

  return objectAssign({}, state, {
    checkout,
  });
});

handlers.set(actions.PRODUCT_LIKE, (state, { productId }) => {
  const pIndex = state.stats.data.likes.products.findIndex(
    (p) => p.productId === productId
  );

  const newState = cloneDeep(state);

  let product;

  if (newState.product !== null && newState.product._id === productId) {
    product = newState.product;
  }

  if (!product && newState.motoTires.length > 0) {
    product = newState.motoTires.find((p) => p._id === productId);
  }

  if (pIndex === -1) {
    // Toggle add like
    newState.stats.data.likes.products.push({ productId });
    product.likes += 1;
  } else {
    // Toggle remove like
    newState.stats.data.likes.products.splice(pIndex, 1);
    product.likes += 1;
  }

  cStorage.setObject("stats", newState.stats);

  return newState;
});

handlers.set(actions.SHOP_LIKE, (state, { shopId }) => {
  const pIndex = state.stats.data.likes.shops.findIndex(
    (p) => p.shopId === shopId
  );

  const newState = cloneDeep(state);

  const shops =
    newState.checkout.shops.length > 0
      ? newState.checkout.shops
      : newState.shopIndex.shops;

  const shop = shops.find((p) => p._id === shopId) || newState.shopIndex.shop;

  if (pIndex === -1) {
    // Toggle add like
    newState.stats.data.likes.shops.push({ shopId });
    shop.likes += 1;
  } else {
    // Toggle remove like
    newState.stats.data.likes.shops.splice(pIndex, 1);
    shop.likes -= 1;
  }

  cStorage.setObject("stats", newState.stats);

  return newState;
});

handlers.set(actions.STATS_UPDATED, (state, { stats }) => {
  return objectAssign({}, state, { stats });
});

export default handlers;
