import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { getCartTotal } from "./../common/calculators";

import Alert from "@material-ui/lab/Alert";

import { vsprintf } from "sprintf-js";
import CreditCard from "./CreditCard";

const hebrewLabels = {
  selectPayMethod: "בחר כיצד לשלם",
  titles: {
    paymentMethod: "אופן התשלום",
    paymentInfo: "פרטי התשלום",
  },
  transferNotes: "הטיפול בהזמנה עלול להתעכב בעד 3 ימי עסקים בהעברה",
  ccNote: "מקבלים את כל הכרטיסים מלבד אמריקן ודיינרס",
  shipping: "משלוח עד הבית עם שליח",
  bitNote: "האפשרות תחזור בתחילת 2023",
  install: "הרכבה",
  pickup: "איסוף עצמי",
  disclaimer: "הרכבה או משלוח בתוספת תשלום",
  continue: "המשך",
  onphone:
    "בחרת בכרטיס אשראי בטלפון, עם סיום תהליך ההזמנה אחד מנציגנו יצור עמך קשר לקחת פרטי אשראי",
  cash: "בחרת לשלם במזומן, אופציה זאת זמינה רק באיסוף או הרכבה עצמית נא להכין את הסכום המדויק ולהשאיר בנקודת ההרכה הנבחרת אצל המתקין / להעביר לנציגנו בנקודת האיסוף במעמד האיסוף",
};

const styles = (theme) => ({
  content: {
    padding: 16,
  },
  formControl: {
    margin: theme.spacing(),
    width: "70%",
  },
  paymentMethodNote: {
    fontSize: "10pt",
    position: "relative",
    bottom: "10px",
    color: "#4d4c4c",
  },
  smallNote: {
    color: "#8a8a8a",
  },
  formLabel: {
    padding: theme.spacing(),
    paddingLeft: 0,
  },
  sectionTitle: {
    fontSize: 20,
  },
  paymentForkGrid: {
    width: "100%",
  },
  "@media (min-width: 1024px)": {
    paymentForkGrid: {
      width: "initial",
    },
  },
});

const paymentMethods = [
  {
    displayName: "כרטיס אשראי",
    value: "creditcard",
    notes: hebrewLabels.ccNote,
  },
  {
    displayName: "אשראי בטלפון",
    value: "creditcard_onphone",
  },
  {
    displayName: "העברה בנקאית",
    value: "transfer",
    notes: hebrewLabels.transferNotes,
  },
  // {
  //   displayName: "אפליקציית Bit",
  //   value: "transfer_bit",
  //   disabled: true,
  //   notes: hebrewLabels.bitNote,
  // },
];

const elementValidators = {
  creditcard({
    cardNumber = "",
    nameOnCard = "",
    cardExpiryMonth = "",
    cardExpiryYear = "",
    cardCVV = "",
    cardHolderId = "",
  }) {
    if (
      cardNumber.length > 0 &&
      nameOnCard.length > 0 &&
      cardExpiryMonth !== "" &&
      cardExpiryMonth > 0 &&
      cardExpiryYear !== "" &&
      cardExpiryYear > 0 &&
      cardCVV.length > 0 &&
      cardHolderId.length > 0
    ) {
      return true;
    }
    return false;
  },
  transfer: () => {
    return true;
  },
  transfer_bit: () => {
    return true;
  },
  creditcard_onphone: () => {
    return true;
  },
  cash: () => {
    return true;
  },
};

class Payment extends React.Component {
  changePaymentMethod = (event) => {
    this.props.onChangePaymentMethod(event.target.value);
  };

  render() {
    const {
      classes,
      checkoutError,
      checkoutErrorMessage,
      selectedPaymentMethod,
      cartProducts,
      cart,
      checkout,
    } = this.props;

    const { cartSubTotal, installTotal } = getCartTotal({
      cart,
      checkout,
      cartProducts,
    });
    const textsTemplates = {
      transfer: (
        <span>
          פרטי הבנק שלנו לביצוע העברה:
          <br />
          לאחר ביצוע העברה יש להעביר צילום מסך לנייד מספר: 054-9200911
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>בנק:</strong>
                </td>
                <td>בנק לאומי (10)</td>
              </tr>
              <tr>
                <td>
                  <strong>סניף:</strong>
                </td>
                <td>תל אביב עסקים 811</td>
              </tr>
              <tr>
                <td>
                  <strong>מס חשבון:</strong>
                </td>
                <td>85780040</td>
              </tr>
              <tr>
                <td>
                  <strong>שם חשבון:</strong>
                </td>
                <td>ספורטישופ צמיגים בע״מ</td>
              </tr>
              <tr>
                <td>
                  <strong>סכום להעברה:</strong>
                </td>
                <td>
                  <strong>{vsprintf("%.2f", [cartSubTotal])} ₪</strong>
                </td>
              </tr>
              {checkout.sip === "install" && (
                <tr>
                  <td colSpan="2" className={classes.smallNote}>
                    * הסכום הינו עבור הצמיגים בלבד <br />
                    {installTotal > 0 && (
                      <React.Fragment>
                        את הסכום {vsprintf("%.2f", [installTotal])} ₪ יש לשלם
                        במוסך בסיום ההתקנה
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </span>
      ),
      bit: (
        <span>
          מספר הביט שלנו הוא: 054-9200911
          <br />
          נא להעביר את הסכום הבא:{" "}
          <strong>{vsprintf("%.2f", [cartSubTotal])} ₪</strong>
          <br />
          {checkout.sip === "install" && (
            <span className={classes.smallNote}>
              * הסכום הינו עבור הצמיגים בלבד <br />
              {installTotal > 0 && (
                <React.Fragment>
                  את הסכום {vsprintf("%.2f", [installTotal])} ₪ יש לשלם במוסך
                  בסיום ההתקנה
                </React.Fragment>
              )}
            </span>
          )}
        </span>
      ),
    };

    const elements = {
      creditcard: (
        <CreditCard
          data={this.props.data}
          checkout={checkout}
          cartProducts={cartProducts}
          cart={cart}
          onFormFieldChange={this.props.onFormFieldChange}
        />
      ),
      creditcard_onphone: <Typography>{hebrewLabels.onphone}</Typography>,
      cash: <Typography>{hebrewLabels.cash}</Typography>,
      transfer: (
        <Typography component="span">{textsTemplates.transfer}</Typography>
      ),
      transfer_bit: (
        <Typography component="span">{textsTemplates.bit}</Typography>
      ),
    };

    return (
      <div className={classes.content}>
        {checkoutError && (
          <Alert severity="error">{checkoutErrorMessage}</Alert>
        )}

        <Grid container spacing={0}>
          <Grid item lg={6} className={classes.paymentForkGrid}>
            <Typography component="h2" className={classes.sectionTitle}>
              {hebrewLabels.titles.paymentMethod}
            </Typography>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                {hebrewLabels.selectPayMethod}
              </FormLabel>
              <RadioGroup
                aria-label={hebrewLabels.pickSIPmethodPreface}
                name="payment_method_selector"
                className={classes.group}
                onChange={this.changePaymentMethod}
                value={selectedPaymentMethod}
              >
                {paymentMethods.map(
                  (
                    { displayName, value, disabled = false, notes = "" },
                    index
                  ) => (
                    <React.Fragment>
                      <FormControlLabel
                        key={index}
                        value={value}
                        control={<Radio />}
                        label={displayName}
                        disabled={disabled}
                      />
                      {notes.length > 0 && (
                        <FormHelperText>
                          <Typography className={classes.paymentMethodNote}>
                            {notes}
                          </Typography>
                        </FormHelperText>
                      )}
                    </React.Fragment>
                  )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <Typography component="h2" className={classes.sectionTitle}>
              {hebrewLabels.titles.paymentInfo}
            </Typography>
            {elements[selectedPaymentMethod]}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Payment.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  selectedPaymentMethod: PropTypes.string.isRequired,
  checkout: PropTypes.object.isRequired,
  cartProducts: PropTypes.array.isRequired,
  cart: PropTypes.object.isRequired,
  onFormFieldChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(Payment);
