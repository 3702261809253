import { restoreCartFromCookieStorage } from "./utils/cart";

export default {
  catalog: {
    widths: [],
    profiles: [],
    rims: [],
    admin: {
      orders: [],
      products: [],
      during: false,
    },
    popularSizes: [],
    topSellers: [],
    motoTires: [],
    getCreationTiresPredictedYearsForPreorder: {},
    product: null,
    productNotFound: false,
    category: {
      name: "",
      seo: {
        title: "מבצעי צמיגים בספורטישופ!",
      },
    },
    currentShipment: "",
    nextShipmentDeadline: "",
    bestOfferEANs: [],
    productPending: false,
    cart: restoreCartFromCookieStorage(),
    thankyou: false,
    stats: {
      data: {
        likes: {
          products: [],
          shops: [],
        },
      },
      visitorId: null,
    },
    shopIndex: {
      cities: [],
      shops: [],
      shop: null,
      selectedCity: "",
    },
    checkout: {
      sip: "install",
      cities: [],
      shops: [],
      dates: [],
      slots: [],
      selectedCityId: "",
      selectedShop: "",
      installDate: "",
      installSlot: "",
      activeStep: 0,
      shipping: {},
      personal: {},
      payment: {},
      additionalNotes: "",
      loading: false,
      checkoutError: false,
      checkoutErrorMessage: "",
      selectedPaymentMethod: "creditcard",
    },
    selectedWidth: "",
    selectedProfile: "",
    selectedRim: "",
    filters: {
      hideSets: false,
    },
    listPending: true,
    snackbar: null,
    toggleSnackbar: false,
    cartProducts: [],
  },
};
