import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Divider from "@material-ui/core/Divider";

import Paper from "@material-ui/core/Paper";

import Button from "@material-ui/core/Button";

import Personal from "./Personal";
import Payment from "./Payment";
import Confirm from "./Confirm";
import Loader from "./Loader";
import ThankYou from "./ThankYou";

function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };

  if (typeof gtag === "function") {
    gtag("event", "conversion", {
      send_to: "AW-964127371/z-CtCKa8v6YZEIvV3csD",
      transaction_id: "",
      event_callback: callback,
    });
  }

  return false;
}

const styles = (theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 100000,
  },
  checkoutRoot: {
    margin: theme.spacing(2),
    marginTop: 35,
    background: "#fefefe",
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  loaderRoot: {
    marginTop: 70,
    display: "flex",
    justifyContent: "center",
  },
  rightIcon: {
    marginLeft: theme.spacing(),
  },
  button: {
    margin: 8,
    fontSize: "13pt",
    fontWeight: 500,
  },
  mobileStepperCont: {
    display: "flex",
    justifyContent: "center",
  },
  stepperMobile: {
    paddingLeft: 17,
  },
  "@media (min-width: 1024px)": {
    stepperMobile: {
      paddingLeft: 24,
    },
    checkoutRoot: {
      marginTop: 90,
      width: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
    loaderRoot: {
      width: 900,
    },
    stepperControl: {
      margin: 8,
      marginBottom: 0,
      textAlign: "right",
    },
    stepperRoot: {
      paddingBottom: 8,
    },
    stepRoot: {
      margin: 16,
    },
  },
});

const hebrewLabels = {
  nextButton: "המשך",
  backButton: "אחורה",
  finishButton: "אישור הזמנה",
};

function validateEmail(email) {
  // Regular expression for validating an email
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
}

class CheckoutPage extends React.Component {
  componentDidMount() {
    // Load up the product given or redirect to not found page.

    if (this.props.catalog.cart.items.length > 0) {
      this.props.actions.getCartProducts(this.props.catalog.cart);
    }
  }

  onContinueSIPFork = () => {
    this.handleNext();
  };

  onContinuePaymentStep = () => {
    /* eslint no-undef: 0 */
    if (typeof fbq === "function") {
      fbq("track", "AddPaymentInfo");
    }
    this.handleNext();
  };

  onChangeInstallCity = (event) => {
    this.props.actions.changeInstallCity(event.target.value);
    this.props.actions.getShopsByCity(event.target.value);
  };

  onPickShop = (shopId) => {
    this.props.actions.pickShopById(shopId);
    this.props.actions.getOpenDatesForShop(shopId);
  };

  onClearPickShop = () => {
    this.props.actions.clearPickShop();
  };

  onPickInstallDate = (date, shopId) => {
    this.props.actions.pickInstallDate(date);
    this.props.actions.getInstallSlotsForDate(date, shopId);
  };

  onPickInstallSlot = (slot) => {
    this.props.actions.pickInstallSlot(slot);
  };

  onChangePaymentMethod = (method) => {
    this.props.actions.changePaymentMethod(method);
  };

  setStepperLoading = (aBool) => {
    this.props.actions.setLoaderIsLoading(aBool);
  };

  onSetLoaderIsLoading = (value) => {
    this.props.actions.setLoaderIsLoading(value);
  };

  getInstallCities = () => {
    this.props.actions.getInstallCities();
  };

  handleSipChange = (value) => {
    this.props.actions.changeSip(value);
  };

  handleBack = (activeStep) => {
    this.props.actions.setCheckoutActiveStep(activeStep - 1);
    // this.setState({
    //   activeStep: this.state.activeStep - 1,
    // });
  };

  handleNext = (activeStep) => {
    if (activeStep + 1 === 3) {
      this.confirmContinue();
    }

    // Metrics
    this.props.actions.collectMetric({
      kind: "CHECKOUT_PASSED_STAGE_1",
      personal: this.props.catalog.checkout.personal,
    });

    this.props.actions.setCheckoutActiveStep(activeStep + 1);

    // this.setState({
    //   activeStep: this.state.activeStep + 1,
    // });
  };

  personalFormFieldChanged = (name, value) => {
    this.props.actions.onFormFieldChangePersonalStep(name, value);
  };

  paymentFormFieldChanged = (name, value) => {
    this.props.actions.onFormFieldChangeInPaymentStep(name, value);
  };

  //New function for additional notes
  additionalNotesFieldChanged = (name, value) => {
    this.props.actions.onTextAreaChangeNotes(name, value);
  };

  shippingFormFieldChanged = (name, value) => {
    this.props.actions.onFormFieldChangeInShippingStep(name, value);
  };

  confirmContinue = () => {
    this.setStepperLoading(true);
    const { cart, checkout, cartProducts } = this.props.catalog;
    const purchasedEans = [];

    const cartTotal = cart.items.reduce((total, item) => {
      const productIndex = cartProducts.findIndex(
        (product) => product.ean === item.ean
      );

      const product = cartProducts[productIndex];
      purchasedEans.push(product.ean);
      return total + product.price * item.quantity;
    }, 0);

    const data = {
      content_ids: purchasedEans,
      content_type: "product",
      value: cartTotal,
      currency: "ILS",
    };

    /* eslint no-undef: 0 */
    // if (typeof fbq === "function") {
    //   fbq("track", "Purchase", data);
    // }

    // gtag_report_conversion("");

    this.props.actions.submitOrder(cart, checkout);
  };

  reportLike = (shopId) => {
    this.props.actions.reportLikeEventForShop(shopId);
  };

  render() {
    const { classes, catalog } = this.props;
    const { checkout, orderId, thankyou = false, cart } = catalog;
    const { activeStep, checkoutError, checkoutErrorMessage, loading } =
      checkout;

    let content,
      stepContent,
      isNextDisabled = true;

    const stepperSteps = ["משלוח", "תשלום", "אישור", "סיום"];

    const steps = [
      {
        name: "personal",
        element: (
          <Personal
            onFormFieldChange={this.personalFormFieldChanged}
            checkout={checkout}
            catalog={catalog}
            handleSipChange={this.handleSipChange}
            getInstallCities={this.getInstallCities}
            onChangeInstallCity={this.onChangeInstallCity}
            onPickShop={this.onPickShop}
            onClearPickShop={this.onClearPickShop}
            onPickInstallDate={this.onPickInstallDate}
            onPickInstallSlot={this.onPickInstallSlot}
            reportLike={this.reportLike}
            onFormFieldChangeForShipping={this.shippingFormFieldChanged}
            data={checkout.personal}
            onContinue={this.onContinueSIPFork}
          />
        ),
      },
      {
        name: "payment",
        element: (
          <Payment
            checkoutError={checkoutError}
            checkoutErrorMessage={checkoutErrorMessage}
            data={checkout.payment}
            selectedPaymentMethod={checkout.selectedPaymentMethod}
            checkout={checkout}
            cartProducts={catalog.cartProducts}
            cart={cart}
            onFormFieldChange={this.paymentFormFieldChanged}
            onChangePaymentMethod={this.onChangePaymentMethod}
          />
        ),
      },
      {
        name: "confirm",
        element: (
          <Confirm
            data={catalog}
            checkout={checkout}
            cartProducts={catalog.cartProducts}
            checkoutError={checkoutError}
            checkoutErrorMessage={checkoutErrorMessage}
            cart={cart}
            onFormFieldChange={this.paymentFormFieldChanged}
            onTextAreaChange={this.additionalNotesFieldChanged}
            onContinue={this.confirmContinue}
          />
        ),
      },
      {
        name: "thankyou",
        element: (
          <ThankYou
            orderId={orderId}
            resetCartConditions={this.props.actions.resetCartConditions}
          />
        ),
      },
    ];

    // Validations area
    let validationPassed = false;
    switch (activeStep) {
      case 0: // Shipping
        const { name = "", phone = "", emailaddress = "" } = checkout.personal;
        let { personal } = checkout;

        if (
          name.length > 0 &&
          phone.length > 0 &&
          emailaddress.length > 10 &&
          validateEmail(emailaddress)
        ) {
          if (checkout.sip === "install") {
            if (checkout.selectedShop !== "") {
              const selectedShop = checkout.shops.find(
                (shop) => shop.id === checkout.selectedShop
              );
              if (
                "manualBooking" in selectedShop &&
                selectedShop.manualBooking === true
              ) {
                validationPassed = true;
              } else if (checkout.installSlot !== "") {
                validationPassed = true;
              }
            }
          } else if (checkout.sip == "shipping") {
            let {
              name = personal.name,
              phone = personal.phone,
              address = "",
              city = "",
            } = checkout.shipping;
            if (
              name.length > 0 &&
              phone.length > 0 &&
              address.length > 0 &&
              city.length > 0
            ) {
              validationPassed = true;
            }
          } else if (checkout.sip === "pickup") {
            validationPassed = true;
          }
        }
        break;

      case 1: // Payment
        if (checkout.selectedPaymentMethod === "creditcard") {
          const {
            cardNumber = "",
            nameOnCard = "",
            cardExpiryMonth = "",
            cardExpiryYear = "",
            cardCVV = "",
            cardHolderId = "",
          } = checkout.payment;

          if (
            cardNumber.length > 7 &&
            nameOnCard.length > 3 &&
            cardExpiryMonth !== "" &&
            cardExpiryYear !== "" &&
            cardCVV.length > 2 &&
            cardHolderId.length > 5
          ) {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case 2: // Confirm
        const { check_tos = false } = checkout.payment;
        if (check_tos) {
          validationPassed = true;
        }
        break;
    }

    if (validationPassed) {
      isNextDisabled = false;
    }

    if (loading === true && !thankyou) {
      stepContent = <Loader />;
    } else {
      if (thankyou) {
        stepContent = steps[3].element;
      } else {
        stepContent = steps[activeStep].element;
      }
    }

    const preCheckoutCond =
      catalog.cartProducts.length > 0 &&
      catalog.cart.items.length > 0 &&
      !thankyou;

    if (preCheckoutCond || thankyou === true) {
      content = (
        <Paper className={classes.stepperRoot}>
          {!thankyou && (
            <Stepper activeStep={activeStep} className={classes.stepperMobile}>
              {stepperSteps.map((label, _) => {
                const props = {};
                const labelProps = {};

                return (
                  <Step key={label} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
          <div className={classes.stepRoot}>{stepContent}</div>
          <Divider />
          {activeStep !== 3 && (
            <div className={classes.stepperControl}>
              <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={() => this.handleBack(activeStep)}
                className={classes.button}
              >
                {hebrewLabels.backButton}
              </Button>

              <Button
                variant="contained"
                disabled={isNextDisabled}
                color="primary"
                onClick={() => this.handleNext(activeStep)}
                className={classes.button}
              >
                {activeStep === steps.length - 1
                  ? hebrewLabels.finishButton
                  : hebrewLabels.nextButton}
              </Button>
            </div>
          )}
        </Paper>
      );
    } else {
      content = (
        <Paper className={classes.stepperRoot} style={{ paddingTop: 16 }}>
          <Loader />
        </Paper>
      );
    }

    return <div className={classes.checkoutRoot}>{content}</div>;
  }
}

CheckoutPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CheckoutPage);
