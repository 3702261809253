import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";

import { vsprintf } from "sprintf-js";

import { getCartItemsCount } from "./../../redux/reducers/utils/cart";
import commons from "./../common/commons";
import { getCartTotal } from "./../common/calculators";

import { Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const hebrewLabels = {
  stepTitle: "סיכום הזמנה",
  continue: "אישור הזמנה",
  th: {
    price: "מחיר",
    prodName: "שם המוצר",
    quantity: "כמות",
    unitPrice: "מחיר",
    line: "סה״כ שורה",
    appointment: "מועד התקנה",
    installTirePrice: "מחיר התקנה",
    installQuantity: "כמות התקנות",
    serviceName: "סוג שירות המשלוח",
    shopName: "שם המוסך",
  },
  totalForTires: "סה״כ לתשלום באתר:",
  titles: {
    cartSummary: "סיכום הפריטים",
    deliverySummary: "סיכום המשלוח",
  },
  pickup:
    "בחרת איסוף עצמי, עם סיום ההזמנה הצוות שלנו יצור איתך קשר לתיאום האיסוף",
  shipping: "משלוח אקספרס 24-72 שעות",
  additionalNotes: "הערות להזמנה",
  confirmText: "לחץ על 'אישור הזמנה' לסיום",
  confirmTosBeenRead: "אני מאשר שקראתי ושאני מסכים ל",
  tos: "תנאי השימוש",
  confirmTosBeenReadLastPart: " באתר",
};

const styles = (theme) => ({
  content: {
    padding: 16,
  },
  productImage: {
    height: 60,
  },
  acceptGrid: {
    padding: 16,
    paddingBottom: 0,
  },
  deliveryGrid: {
    marginTop: 25,
  },
  pickup: {
    padding: 16,
  },
  shopCell: {
    lineHeight: "21px",
  },
  imageCell: {
    width: 80,
    textAlign: "center",
  },
  tdName: {
    whiteSpace: "nowrap",
  },
  sectionTitle: {
    fontSize: 20,
  },
  table: {
    display: "table",
  },
  desktopConfirmCartContent: {
    display: "none",
  },
  mobileConfirmCartContent: {
    margin: "8px 0",
    display: "block",
  },
  productNameCellMobile: {
    padding: "8px 16px",
  },
  tableGridSpacing: {
    padding: 8,
  },
  containerTextArea: {
    padding: 8,
  },
  textArea: {
    margin: "8px 0",
    width: "20rem",
    height: "100%",
  },
  "@media (min-width: 1024px)": {
    desktopConfirmCartContent: {
      display: "block",
    },
    mobileConfirmCartContent: {
      display: "none",
    },
    textArea: {
      width: "30rem",
    },
  },
  "@media (max-width: 380px)": {
    textArea: {
      width: "100%",
    },
  },
});

class Confirm extends React.Component {
  handleChange = (name) => (event) => {
    this.props.onFormFieldChange(name, event.target.checked);
  };

  //For textArea
  handleChangeTextArea = (event) => {
    const { value, name, id } = event.target;
    this.props.onTextAreaChange(id || name, value);
  };

  getMobileSummaryTable({ items, classes }) {
    return (
      <React.Fragment>
        <Grid container spacing={0}>
          {items
            .filter((item) => !item.hide)
            .map((item, index) => {
              return (
                <Grid
                  item
                  sm={6}
                  className={classes.tableGridSpacing}
                  key={index}
                >
                  <Typography component="p">
                    <strong>{item.title}</strong> <br />
                    {item.data}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const {
      classes,
      data: catalog,
      cartProducts,
      checkoutError,
      checkoutErrorMessage,
    } = this.props;
    const { cart, checkout } = catalog;
    const { sip, installSlot, installDate, selectedShop, shops } = checkout;
    const shop = shops.find((s) => s.id === selectedShop);
    const totalCartItems = getCartItemsCount();
    const { cartSubTotal } = getCartTotal({ cart, checkout, cartProducts });

    const { check_tos = false } = checkout.payment;

    let freeShipping = false;
    let tireQuantity = 0;

    cart.items.forEach((item) => {
      if ("isSet" in item && item.isSet === true) {
        tireQuantity += 2;
      } else {
        tireQuantity += 1;
      }

      if ("free_shipping" in item && item.free_shipping === true) {
        freeShipping = true;
      }
    });

    let sipContent,
      sipTotal = 0;

    const dateParts = installDate.split("-");
    let manualBooking = false;

    if (sip === "install") {
      manualBooking = "manualBooking" in shop && shop.manualBooking === true;

      if (tireQuantity > 1) {
        sipTotal = 0;
      } else {
        sipTotal = freeShipping ? 0 : 60;
      }

      sipContent = (
        <React.Fragment>
          <div className={classes.desktopConfirmCartContent}>
            {sipTotal > 0 && (
              <div>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        {hebrewLabels.th.serviceName}
                      </TableCell>
                      <TableCell align="left">
                        {hebrewLabels.th.price}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.shopCell}>
                        {hebrewLabels.shipping}
                      </TableCell>
                      <TableCell>{vsprintf("%.2f", [sipTotal])} ₪</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{hebrewLabels.th.shopName}</TableCell>
                  {!manualBooking && (
                    <TableCell align="left">
                      {hebrewLabels.th.appointment}
                    </TableCell>
                  )}
                  <TableCell align="left">
                    {hebrewLabels.th.installTirePrice}
                  </TableCell>
                  <TableCell align="center">
                    {hebrewLabels.th.installQuantity}
                  </TableCell>
                  <TableCell align="left">{hebrewLabels.th.line}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.shopCell}>
                    {shop.displayName} <br />
                    {shop.address} <br />
                    {shop.phone}
                  </TableCell>
                  {!manualBooking && (
                    <TableCell className={classes.shopCell}>
                      {dateParts[2]}.{dateParts[1]}.{dateParts[0]} <br />
                      {vsprintf("%d:00 - %d:00", [
                        installSlot + 2,
                        installSlot,
                      ])}
                    </TableCell>
                  )}
                  <TableCell>{vsprintf("%.2f", [shop.price])} ₪</TableCell>
                  <TableCell align="center">{tireQuantity}</TableCell>
                  <TableCell>
                    {vsprintf("%.2f", [shop.price * tireQuantity])} ₪
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className={classes.mobileConfirmCartContent}>
            {this.getMobileSummaryTable({
              classes,
              items: [
                {
                  title: hebrewLabels.th.serviceName,
                  data: hebrewLabels.shipping,
                },
                {
                  title: hebrewLabels.th.price,
                  data: `${vsprintf("%.2f", [sipTotal])} ₪`,
                },
              ],
            })}

            {this.getMobileSummaryTable({
              items: [
                {
                  title: hebrewLabels.th.shopName,
                  data: (
                    <React.Fragment>
                      {shop.displayName} <br />
                      {shop.address} <br />
                      {shop.phone}
                    </React.Fragment>
                  ),
                },
                {
                  title: hebrewLabels.th.appointment,
                  hide: manualBooking,
                  data: (
                    <React.Fragment>
                      {dateParts[2]}.{dateParts[1]}.{dateParts[0]} <br />
                      {vsprintf("%d:00 - %d:00", [
                        installSlot + 2,
                        installSlot,
                      ])}
                    </React.Fragment>
                  ),
                },
                {
                  title: hebrewLabels.th.installTirePrice,
                  data: (
                    <React.Fragment>
                      {vsprintf("%.2f", [shop.price])} ₪
                    </React.Fragment>
                  ),
                },
                {
                  title: hebrewLabels.th.installQuantity,
                  data: tireQuantity,
                },
                {
                  title: hebrewLabels.th.line,
                  data: `${vsprintf("%.2f", [shop.price * tireQuantity])} ₪`,
                },
              ],
              classes,
            })}
          </div>
        </React.Fragment>
      );
    } else if (sip === "shipping") {
      if (tireQuantity > 1) {
        sipTotal = 0;
      } else {
        sipTotal = freeShipping ? 0 : 60;
      }

      sipContent = (
        <div>
          <div className={classes.desktopConfirmCartContent}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {hebrewLabels.th.serviceName}
                  </TableCell>
                  <TableCell align="left">{hebrewLabels.th.price}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.shopCell}>
                    {hebrewLabels.shipping}
                  </TableCell>
                  <TableCell>{vsprintf("%.2f", [sipTotal])} ₪</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className={classes.mobileConfirmCartContent}>
            {this.getMobileSummaryTable({
              classes,
              items: [
                {
                  title: hebrewLabels.th.serviceName,
                  data: hebrewLabels.shipping,
                },
                {
                  title: hebrewLabels.th.price,
                  data: `${vsprintf("%.2f", [sipTotal])} ₪`,
                },
              ],
            })}
          </div>
        </div>
      );
    } else {
      sipContent = (
        <div className={classes.pickup}>
          <Typography component="p">{hebrewLabels.pickup}</Typography>
        </div>
      );
    }

    return (
      <div className={classes.content}>
        <Grid container spacing={0}>
          <Grid item lg={12}>
            <Typography component="h2" className={classes.sectionTitle}>
              {hebrewLabels.titles.cartSummary}
            </Typography>

            <div className={classes.desktopConfirmCartContent}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell align="left">
                      {hebrewLabels.th.prodName}
                    </TableCell>
                    <TableCell align="left">
                      {hebrewLabels.th.unitPrice}
                    </TableCell>
                    <TableCell align="center">
                      {hebrewLabels.th.quantity}
                    </TableCell>
                    <TableCell align="left">{hebrewLabels.th.line}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.items.map((item, index) => {
                    const productIndex = cartProducts.findIndex(
                      (product) => product.ean === item.ean
                    );
                    const product =
                      productIndex !== -1 ? cartProducts[productIndex] : null;
                    if (product === null) {
                      return <React.Fragment></React.Fragment>;
                    }

                    const { quantity } = item;
                    const { displayName, displaySize, price, cdn, ean } =
                      product;

                    const primaryPhoto = commons.getProductPrimaryImage(
                      cdn,
                      ean
                    );

                    return (
                      <TableRow key={index}>
                        <TableCell className={classes.imageCell}>
                          <img
                            src={primaryPhoto.url}
                            className={classes.productImage}
                          />
                        </TableCell>
                        <TableCell className={classes.tdName}>
                          {displayName} <br />
                          {displaySize}
                        </TableCell>
                        <TableCell>{vsprintf("%.2f", [price])} ₪</TableCell>
                        <TableCell align="center">{quantity}</TableCell>
                        <TableCell>
                          {vsprintf("%.2f", [price * quantity])} ₪
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      {hebrewLabels.totalForTires}
                    </TableCell>
                    <TableCell>{vsprintf("%.2f", [cartSubTotal])} ₪</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div className={classes.mobileConfirmCartContent}>
              {cart.items.map((item, index) => {
                const productIndex = cartProducts.findIndex(
                  (product) => product.ean === item.ean
                );
                const product =
                  productIndex !== -1 ? cartProducts[productIndex] : null;
                if (product === null) {
                  return <React.Fragment></React.Fragment>;
                }

                const { quantity } = item;
                const { displayName, displaySize, price, cdn, ean } = product;

                const primaryPhoto = commons.getProductPrimaryImage(cdn, ean);

                return (
                  <React.Fragment key={index}>
                    <Grid container spacing={0}>
                      <Grid item md={4}>
                        <img
                          src={primaryPhoto.url}
                          className={classes.productImage}
                        />
                      </Grid>
                      <Grid
                        item
                        md={8}
                        className={classNames(
                          classes.tdName,
                          classes.productNameCellMobile
                        )}
                      >
                        <Typography component="p">
                          {displayName} <br />
                          {displaySize}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item sm={4} className={classes.tableGridSpacing}>
                        <Typography component="p">
                          <strong>{hebrewLabels.th.unitPrice}</strong> <br />
                          {vsprintf("%.2f", [price])} ₪
                        </Typography>
                      </Grid>
                      <Grid item sm={4} className={classes.tableGridSpacing}>
                        <Typography component="p">
                          <strong>{hebrewLabels.th.quantity}</strong> <br />
                          {quantity}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} className={classes.tableGridSpacing}>
                        <Typography component="p">
                          <strong>{hebrewLabels.th.line}</strong> <br />
                          {vsprintf("%.2f", [price * quantity])} ₪
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                      <Grid item lg={12}>
                        <Typography component="p">
                          {hebrewLabels.totalForTires} <br />
                          <strong>{vsprintf("%.2f", [cartSubTotal])} ₪</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </div>
          </Grid>

          <Grid item lg={12} className={classes.deliveryGrid}>
            <Typography component="h2" className={classes.sectionTitle}>
              {hebrewLabels.titles.deliverySummary}
            </Typography>

            {sipContent}
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={0}>
          <Grid item lg={12} className={classes.containerTextArea}>
            <Typography component="p">
              {hebrewLabels.additionalNotes}
            </Typography>

            <TextField
              className={classes.textArea}
              id="additionalNotes"
              multiline
              placeholder=""
              variant="outlined"
              onChange={this.handleChangeTextArea}
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={0}>
          <Grid item lg={12} className={classes.acceptGrid}>
            <Typography component="p">{hebrewLabels.confirmText}</Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={check_tos}
                  onChange={this.handleChange("check_tos")}
                  value="check_tos"
                  color="primary"
                />
              }
              label={
                <span>
                  {hebrewLabels.confirmTosBeenRead}
                  <a target="_blank" href="/terms-of-use">
                    {hebrewLabels.tos}
                  </a>
                  {hebrewLabels.confirmTosBeenReadLastPart}
                </span>
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Confirm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  checkout: PropTypes.object.isRequired,
  cartProducts: PropTypes.array.isRequired,
  onFormFieldChange: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(Confirm);
